@use "../abstracts/" as *;
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //  background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%);
    // background: linear-gradient(122.26deg, #aa077a 0%, #010045 47.82%, #24b783 98.96%);
    background: linear-gradient(120.68deg, #AA077A -15.68%, #010045 51.29%, #24B783 122.93%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fade-in 1s forwards, pulse 2s ease-in-out infinite;
    z-index: 10001;
}

.splash-screen img {
    width: 150px;
    height: 150px; /* Adjust the size as needed */
    border-radius: 50%;
    padding: 5px;

    animation: rotate 3s linear infinite, border-dot 2s infinite;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

@keyframes border-dot {
    0% {
        border-style: solid;
        border-color: #dec7ff;
        border-width: 10px;
    }
    25% {
        border-style: solid;
        border-color: #24B783;
        border-width: 20px;
    }
    50% {
        border-style: solid;
        border-color: #5c27fe;
        border-width: 30px;
    }
    75% {
        border-style: solid;
        border-color: #AA077A;
        border-width: 40px;
    }
    100% {
        border-style: solid;
        border-color: #010045;
        border-width: 50px;
    }
}
