.filter-container{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 20px;
}

@media (max-width: 768px) {
    .filter-container {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
    }

    button {
      width: 80%;
    }
  }