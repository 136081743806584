
// Color
$text-color : #FFFFFF;
$white: #fff;

$secondary-color: #DEC7FF;
$primary-color: #24B783;
//$primary-color: #5C27FE;
$primary-opacity01 : rgba(192,4,222,0.1);
$line : rgba(192,4,222,0.2);



$header-height: 88px;
$header-shrink-height: 90px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1900 : 1900px;


$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0,0,0,0.5);